.login-v2-join-membership-clause {
  padding: 0 16px;

  &__title {
    padding: 24px 0 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  &__checkbox {
    margin: 8px 0 4px;

    &-notice {
      margin: 0 0 8px 24px;
      color: $v2Gray600;
      font-size: 13px;
      line-height: 18px;
    }
  }

  &__sub-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: first baseline;
    padding-left: 24px;

    &__link {
      flex-shrink: 0;
      margin-left: 5px;
      color: $v2Gray600;
      font-size: 13px;
      text-decoration: underline;
      line-height: 18px;
    }
  }

  &__text {
    padding-top: 12px;
    color: $v2Gray600;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    word-break: keep-all;
    word-wrap: break-word;

    &__link {
      text-decoration: underline;
    }
  }
}
