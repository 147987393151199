.login-v2-input {
  $this: &;

  padding: 8px 0;

  @at-root .login-v2-input--layer {
    position: relative;

    #{$this}__wrap {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-color: $v2Gray300;
    }

    #{$this}__layer-list {
      display: block;
    }
  }

  &__label {
    display: inline-block;
    margin: 8px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &__input {
    @include placeholder($v2Gray500);

    appearance: none;
    width: 100%;
    height: 36px;
    padding: 0 8px;
    margin-top: 8px;
    border: 1px solid $gray300;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: $white;
    font-size: 14px;
    color: $black;
    line-height: 20px;
    transition: border 0.2s ease-in-out;
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
    box-shadow: 0 0 0 30px $white inset !important;

    &:first-child {
      margin-top: 0;
    }

    &:disabled {
      border-color: $gray300;
      background-color: $gray200;
      color: $gray500;
      -webkit-box-shadow: 0 0 0 30px $gray200 inset !important;
      box-shadow: 0 0 0 30px $gray200 inset !important;
    }

    &:read-only {
      border-color: $gray300;
      background-color: $gray200;
      -webkit-box-shadow: 0 0 0 30px $gray200 inset !important;
      box-shadow: 0 0 0 30px $gray200 inset !important;
    }

    &:not(:read-only):focus {
      border-color: $oldGray6;
    }

    &[type='text']::-ms-clear,
    &[type='tel']::-ms-clear,
    &[type='email']::-ms-clear,
    &[type='number']::-ms-clear,
    &[type='password']::-ms-reveal,
    &[type='password']::-ms-clear {
      display: none !important;
    }

    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &__danger {
    border-color: $v2Red !important;

    & + #{$this}__validation {
      margin: 8px 0 0 2px;
      font-size: 11px;
      line-height: 14px;
      color: $v2Red;
    }
  }

  &__helper-text {
    margin-top: 8px;
    font-size: 11px;
    line-height: 14px;
    color: $v2Gray500;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    border: 1px solid $v2Gray300;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus-within {
      border-color: $v2Gray500;
      transition: border 0.2s ease-in-out;
    }

    #{$this}__input {
      flex: 0 1 auto;
      height: 34px;
      border: none;
    }
  }

  &__button {
    &-clear {
      display: flex;
      flex: 0 0 auto;
      margin-right: 8px;
    }

    &-eye {
      min-width: 20px;
      height: 20px;
      margin-right: 7px;
      background: url('../img/login-v2/ic-20-eye-closed.svg?v=#{timestamp()}') no-repeat 50% 50%;

      &--active {
        background: url('../img/login-v2/ic-20-eye.svg?v=#{timestamp()}') no-repeat 50% 50%;
      }
    }
  }

  &__layer-list {
    display: none;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    padding: 8px 0;
    border: 1px solid $v2Gray300;
    border-top: none;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-sizing: border-box;
    background-color: $white;
    z-index: 10;

    &__button {
      display: flex;
      width: 100%;
      padding: 11px 16px;
      box-sizing: border-box;
      font-size: 13px;
      color: $v2Gray600;
      text-align: left;
      line-height: 18px;
    }

    &__mail-domain {
      color: $black;
    }
  }
}
