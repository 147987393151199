.identity-check {
  &__title {
    padding: 24px 16px 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    word-break: keep-all;
    word-wrap: break-word;
  }

  &__description {
    padding: 8px 16px 12px;
    font-size: 13px;
    line-height: 18px;
    word-break: keep-all;
    word-wrap: break-word;
  }
}
