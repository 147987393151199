.login-v2-bullet-item {
  padding: 4px 16px;
  display: flex;
  font-size: 13px;
  color: $v2Gray600;
  line-height: 18px;

  &::before {
    display: block;
    width: 7px;
    margin-right: 4px;
    content: '•';
  }

  &--red {
    color: $v2Red;
  }
}
