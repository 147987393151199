.login-v2-dialog {
  @include position(fixed, 50%, null, null, 50%);

  display: none;
  width: calc(100% - 64px);
  max-width: 420px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: $white;
  transform: translate(-50%, -50%);
  z-index: 9999;

  &__contents {
    padding: 20px 16px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    word-break: keep-all;
    word-wrap: break-word;
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0 4px;
    padding: 24px 16px 20px;
  }

  &.active {
    display: block;
  }
}
