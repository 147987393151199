.login-v2-bottom-sheet {
  @include position(fixed, null, null, 0, 0);

  width: 100%;
  max-width: var(--max-width);
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 8px 8px 0 0;
  background-color: $white;
  transform: translateY(calc(100% + 40px));
  transition: transform 0.4s cubic-bezier(0.38, 0.09, 0.12, 1);
  z-index: 9999;

  &::before {
    @include position(absolute, 8px, null, null, 50%);

    overflow: hidden;
    display: block;
    width: 36px;
    height: 4px;
    margin-left: -18px;
    border-radius: 10px;
    background-color: $v2Gray300;
    content: '';
  }

  &.active {
    transform: translateY(0);
  }
}
