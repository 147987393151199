.cell-phone-reauthentication {
  &__title {
    padding: 24px 16px 12px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    word-break: keep-all;
    word-wrap: break-word;
  }

  &__details {
    padding-top: 8px;

    &__agree-text {
      padding: 12px 16px;
      color: $v2Gray600;
      font-size: 13px;
      line-height: 18px;
      word-break: keep-all;
      word-wrap: break-word;
    }
  }

  &__validity-text {
    padding: 12px 16px;
    color: $v2Blue;
    font-size: 13px;
    line-height: 18px;

    &--failed {
      color: $v2Red;
    }
  }

  &__checkbox {
    position: absolute;
    left: 0;
    bottom: 88px;
    margin: 0 16px;
  }
}
