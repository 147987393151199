.login-v2-divider {
  width: calc(100% - 32px);
  height: 1px;
  margin: 12px 16px;
  background-color: $v2Gray500;
  opacity: 0.1;

  &.login-v2-divider--space-4 {
    margin: 4px 16px;
  }

  @at-root .login-v2-join-membership-clause & {
    width: 100%;
    margin: 16px 0 12px;
  }
}
