.login-v2-member__util__login-auto__tooltip {
  @include tooltip($direction: bottom, $top: calc(100% + 6px), $left: -4px, $arrowLeft: calc(50% - 3px));

  padding: 8px;
  font-family: $font-pretendard;
  white-space: nowrap;

  &::before {
    left: 8px;
  }
}
