.cell-phone-verification {
  &__title {
    padding: 24px 16px 12px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    word-break: keep-all;
    word-wrap: break-word;
  }

  &__details {
    padding-top: 8px;

    &__agree-text {
      padding: 12px 16px;
      color: $v2Gray600;
      font-size: 13px;
      line-height: 18px;
    }
  }

  &__checkbox {
    margin: 12px 16px;
  }
}
