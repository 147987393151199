.login-v2-member {
  padding: 16px 16px 0;

  &__captcha {
    margin: 8px 0;
  }

  &__util {
    position: relative;
    // height: 18px;
    padding: 8px 0 12px;

    &__list {
      @include position(absolute, 8px, 0);
    }

    &__item {
      display: inline-block;
      position: relative;
      padding-left: 17px;
      font-size: 13px;
      line-height: 18px;
      color: $v2Gray500;

      &::before {
        @include position(absolute, 2px, null, null, 8px);

        display: block;
        width: 1px;
        height: 13px;
        background-color: $v2Gray400;
        content: '';
      }

      &:first-child {
        &::before {
          display: none;
        }
      }
    }
  }

  &__sns-login {
    margin: 12px 0;
  }

  &__hyundaicard {
    padding: 8px 0 16px;
    text-align: center;

    &__text {
      margin-top: 20px;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    &__caution {
      margin-top: 18px;
      font-size: 13px;
      line-height: 18px;
      color: $v2Gray600;
    }
  }
}
