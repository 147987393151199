.login-v2-join-membership {
  padding: 8px 16px 68px;

  &__hyundaicard {
    padding: 16px 0 8px;
    text-align: center;

    &__text {
      position: relative;
      padding: 36px 0 4px;
      font-size: 13px;
      line-height: 18px;
      color: $v2Gray600;

      &::before {
        display: block;
        position: absolute;
        top: 12px;
        left: 50%;
        width: calc(100%);
        height: 1px;
        background-color: $v2Gray500;
        opacity: 0.1;
        content: '';
        transform: translateX(-50%);
      }

      > p {
        position: relative;
        padding-left: 11px;

        &::before {
          display: block;
          position: absolute;
          top: 0;
          left: 2px;
          content: '•';
        }
      }
    }
  }
}
