.login-v2-find-result {
  &__text-wrapper {
    padding: 24px 16px 12px;

    &__title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }

    &__text {
      margin-top: 4px;
      font-size: 13px;
      color: $v2Gray600;
      line-height: 18px;
    }
  }

  &__result {
    &-list {
      padding: 4px 0;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;
      color: $v2Gray600;
      font-size: 13px;
      line-height: 18px;
    }
  }
}
