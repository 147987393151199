.soldout-login-agree {
  &__title {
    padding: 24px 16px 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;

    &--v2 {
      margin-bottom: 4px;
    }
  }

  // 25.3.31 이후 삭제할 것 --- start
  &__text {
    padding: 8px 16px;
    color: $v2Gray600;
    font-size: 13px;
    line-height: 18px;
  }

  &__button {
    margin: 0 16px 12px;
    color: $v2Gray600;
    font-size: 13px;
    line-height: 18px;
    text-decoration: underline;
  }
  // 25.3.31 이후 삭제할 것 --- end

  &__checkbox-group {
    padding: 4px 16px 0;
  }

  &__sub-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;

    &-title {
      padding: 8px 0 0 24px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }

    &__text {
      padding: 0 0 8px 24px;
      color: $v2Gray600;
      font-size: 11px;
      line-height: 14px;
    }

    &__button {
      color: $v2Gray600;
      font-size: 13px;
      text-decoration: underline;
      line-height: 18px;
    }
  }
}
