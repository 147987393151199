.login-v2 {
  $this: &;

  position: relative;
  min-width: 320px;
  font-family: $font-pretendard;

  .content {
    padding-bottom: 72px;
    padding-bottom: calc(env(safe-area-inset-bottom) + 72px);
    padding-bottom: calc(constant(safe-area-inset-bottom) + 72px);
  }

  &-body {
    #commonLayoutContainer {
      padding-bottom: 0;
    }
  }
}
