.login-v2-radio {
  display: inline-flex;
  align-items: center;

  > [class$='__label'] {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: auto;
    padding: 0 0 0 24px;
    color: $v2Gray600;
    font-size: 13px;
    text-align: left;
    line-height: 18px;
    white-space: normal;
    cursor: pointer;

    &::before,
    &::after {
      display: block;
      border-radius: 100%;
      box-sizing: border-box;
    }

    &::before {
      @include position(absolute, 1px, null, 0, 0);

      width: 16px;
      height: 16px;
      border: 1px solid $v2Gray400;
      content: '';
    }

    &::after {
      @include position(absolute, 9px, null, null, 4px);

      width: 8px;
      height: 8px;
      background-color: $white;
      transform: translateY(-50%);
    }
  }

  > input[type='radio'] {
    // checked
    &:checked + [class$='__label'] {
      color: $black;

      &::before {
        border-color: $black;
        background-color: $black;
      }

      &::after {
        content: '';
      }
    }

    // disabled
    &:disabled {
      &:not(:checked) + [class$='__label'] {
        color: $v2Gray400;

        &::before {
          border-color: $v2Gray300;
          background-color: $v2Gray100;
        }
      }

      &:checked + [class$='__label'] {
        color: $v2Gray600;

        &::before {
          border-color: $v2Gray400;
          background-color: $v2Gray400;
        }
      }

      &:checked + [class$='__label']::after {
        background-color: $v2Gray200;
      }
    }

    &:focus + label {
      outline: 1px dotted $black;
    }
  }
}
