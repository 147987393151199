.login-v2-checkbox {
  $this: &;

  display: inline-flex;
  align-items: center;
  position: relative;
  margin: 8px 0;

  @at-root .login-v2-member__util__login-auto#{$this} {
    margin: 0;
  }

  > [class$='__label'] {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: auto;
    min-height: 16px;
    padding-left: 24px;
    color: $v2Gray600;
    font-size: 13px;
    line-height: 18px;
    text-align: left;
    white-space: normal;
    cursor: pointer;

    &::before,
    &::after {
      display: block;
      box-sizing: border-box;
    }

    &::before {
      @include position(absolute, 0, null, 0, 0);

      display: block;
      width: 16px;
      height: 16px;
      background: url('../img/login-v2/ic-16-checkbox-default.svg?v=#{timestamp()}') no-repeat 50% 50%;
      content: '';
    }
  }

  > input[type='checkbox'] {
    // checked
    &:checked + [class$='__label'] {
      color: $black;

      &::before {
        background: url('../img/login-v2/ic-16-checkbox-checked.svg?v=#{timestamp()}');
      }
    }

    // disabled
    &:disabled {
      color: $v2Gray400;

      &:not(:checked) + [class$='__label'] {
        color: $v2Gray400;
        cursor: default;
      }

      &:not(:checked) + [class$='__label']::before {
        background: url('../img/login-v2/ic-16-checkbox-disabled.svg?v=#{timestamp()}');
      }

      &:checked + [class$='__label']::before {
        color: $v2Gray600;

        background: url('../img/login-v2/ic-16-checkbox-checked-disabled.svg?v=#{timestamp()}');
      }
    }
  }

  > input[type='checkbox']:focus + label {
    outline: 1px dotted $black;
  }
}
