@charset "UTF-8";

@import './include/common/import';

.popup-dim {
  background-color: rgba(0, 0, 0, 0.6);
}

.layer-dim {
  @include position(fixed, 0, 0, 0, 0);

  max-width: var(--max-width);
  margin: 0 auto;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.6);
}

// login 2.0
// component
@import './include/login-v2/_layout';
@import './include/login-v2/_login-v2-member';
@import './include/login-v2/_login-v2-input';
@import './include/login-v2/_login-v2-button';
@import './include/login-v2/_login-v2-checkbox';
@import './include/login-v2/_login-v2-tooltip';
@import './include/login-v2/_login-v2-bottom-text';
@import './include/login-v2/_login-v2-join-membership-clause';
@import './include/login-v2/_login-v2-bottom-sheet';
@import './include/login-v2/_bottom-sheet-join-membership-clause';
@import './include/login-v2/_login-v2-join-membership';
@import './include/login-v2/_login-v2-find-result';
@import './include/login-v2/_login-v2-divider';
@import './include/login-v2/_login-v2-snackbar';
@import './include/login-v2/_login-v2-radio';
@import './include/login-v2/_login-v2-dialog';
@import './include/login-v2/_login-v2-bullet-item';
@import './include/login-v2/_soldout-login';
@import './include/login-v2/_identity-check-detail-list';

// page
@import './include/login-v2/_soldout-login-fail';
@import './include/login-v2/_soldout-login-agree';
@import './include/login-v2/_password-input';
@import './include/login-v2/_identity-check';
@import './include/login-v2/_cell-phone-verification';
@import './include/login-v2/_cell-phone-reauthentication';
