.login-v2-snackbar {
  @include safearea(bottom, 80px);

  display: block;
  position: fixed;
  left: 16px;
  right: 16px;
  max-width: calc(600px - 32px);
  margin: 0 auto;
  box-sizing: border-box;
  transform: translateY(80px);
  visibility: hidden;
  z-index: 100;

  &.login-v2-snackbar--active {
    display: block;
    visibility: visible;
    animation: snackbarUp 0.35s ease-out forwards, snackbarDown 0.35s 3s ease-out forwards;
  }

  &__text {
    width: 100%;
    padding: 9px 12px;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: $v2Gray700;
    color: $white;
    font-size: 13px;
    line-height: 18px;
    word-break: break-all;
  }

  @keyframes snackbarUp {
    0% {
      transform: translateY(80px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes snackbarDown {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(80px);
      opacity: 0;
    }
  }
}
