.login-v2-bottom-text {
  $this: &;

  color: $black;

  &--login-member {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;

    #{$this} {
      &__text {
        margin-right: 6px;
      }

      &__link {
        padding: 3px 6px;
        border: 1px solid $v2Gray300;
        border-radius: 4px;
        box-sizing: border-box;
      }
    }
  }

  &--login-non-member {
    @include safearea(bottom, 0px);

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 50%;
    width: 100%;
    max-width: 600px;
    padding: 12px 16px;
    transform: translateX(-50%);

    #{$this} {
      &__text {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      &__link {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        text-decoration: underline;
      }
    }

    @at-root .login-v2 .login-v2__app-content & {
      position: absolute;
    }
  }
}
