.identity-check-detail-list {
  display: flex;
  align-items: flex-start;
  padding: 4px 16px;
  font-size: 13px;
  line-height: 18px;

  &__title {
    width: 60px;
    margin-right: 16px;
    font-weight: 400;
  }

  &__text {
    width: calc(100% - 76px);
    word-break: keep-all;
    word-wrap: break-word;
  }
}
