.login-v2-button {
  $this: &;

  max-width: var(--max-width);
  box-sizing: border-box;
  text-align: center;

  &-bottom-fixed {
    position: fixed;
    left: 50%;
    bottom: 0;
    width: 100%;
    max-width: var(--max-width);
    padding: 0 16px;
    box-sizing: border-box;
    background-color: $white;
    transform: translateX(-50%);
    z-index: 200;

    &--divider {
      border-top: 1px solid rgba(138, 138, 138, 0.1);
    }

    #{$this}--highlight:last-child {
      @include safearea(padding-bottom, 12px);
    }
  }

  &__item {
    display: block;
    width: 100%;
    height: 40px;
    padding: 10px 16px;
    border: 1px solid $black;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $black;
    text-align: center;

    & + & {
      margin-top: 16px;
    }

    // 다이얼로그 버튼 제어
    @at-root .login-v2-dialog__button {
      #{$this}__item + #{$this}__item {
        margin-top: 0;
      }
    }

    &:disabled,
    &.disabled {
      border-color: $v2Gray200;
      background-color: $v2Gray200;
      color: $v2Gray400;
      cursor: default;
    }

    &--black {
      background-color: $black;
      color: $white;
    }

    &--outline-gray {
      border-color: $v2Gray300;
    }

    &--kakao {
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #fee500;
      background-color: #fee500;

      svg {
        margin-right: 4px;
      }
    }

    &--apple {
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: $v2Gray300;

      svg {
        margin-right: 4px;
      }
    }

    &--toss {
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #0064ff;
      background-color: #0064ff;
      color: $white;

      svg {
        margin-right: 4px;
      }
    }
  }

  &--highlight {
    padding: 12px 0;

    #{$this}__item {
      height: 44px;
      padding: 12px 16px;
    }
  }

  &--login {
    padding: 8px 0;
  }
}
